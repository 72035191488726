@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

body {
    font-family: 'Fraunces', serif;
    margin: 0;
    padding: 0;
    box-sizing: border-box;

    background: linear-gradient(90deg, #F0EBE6 49.5%, #E5D9CE 100%);
}