.hero {
  display: grid;
  grid-template-columns: 1fr 1fr;
  min-height: 616px;
  overflow: hidden;
}

.hero-content {
  padding: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.hero-title {
  font-family: 'Fraunces', serif;
  font-style: normal;
  font-weight: 900;
  font-size: 48px;
  line-height: 120%;
  color: #334155;
  margin-bottom: 1rem;
}

.hero-title div {
  justify-content: start;
}

.hero-description {
  font-family: 'Fraunces', serif;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 140%;
  color: #1C1917; 
  margin-bottom: 2rem;
}

@media (min-width: 640px) {
  .hero-description {
    font-size: 20px;
  }
}

@media (min-width: 768px) {
  .hero-description {
    font-size: 22px;
  }
}

@media (min-width: 1024px) {
  .hero-description {
    font-size: 24px;
  }
}

.hero-button {
  font-family: 'Fraunces', serif;
  font-style: normal;
  font-weight: 900;
  font-size: 24px;
  line-height: 120%;
  /* color: #FFFBEB;
  background-color: #059669; */
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  align-self: flex-start;
}

.auth-buttons button {
  font-family: 'Fraunces', serif;
  font-weight: 700;
  font-size: 16px;
  line-height: 120%;
  padding: 16px 40px;
  border-radius: 166px;
  cursor: pointer;
}

.login-btn {
  color: #059669;
  background: transparent;
  border: 1px solid #059669;
}

.hero-image {
  background: url('/public/assets/hero-image.png') no-repeat left bottom;
  background-size: auto 90%;
  overflow: hidden;
  position: absolute; 
  top: 0;
  right: 0;
  width: 50%;
  height: 80%;
  max-height: 800px;
  z-index: -1;
}

@media (max-width: 1024px) {
  .hero {
    grid-template-columns: 1fr;
    position: relative;

  }

  .hero-image {
    min-height: 500px;
    order: -1;
    position: relative; 
    width: 100%;
    height: 100%;
    background-position: center center;
  }

  .hero-title {
    font-size: 36px;
  }

  .hero-description {
    font-size: 20px;
  }
}

@media (max-width: 768px) {
  .hero-title {
    font-size: 40px;
  }

  .hero-description {
    font-size: 18px;
  }

  .hero-button {
    font-size: 20px;
  }
}