.header {
    position: sticky;
    z-index: 1000;
    width: 100%;
    height: 120px;
    left: 0;
    top: 0;
    background: linear-gradient(90deg, #F0EBE6 49.5%, #E5D9CE 100%);
    backdrop-filter: blur(8px);
}

.container {
    display: grid;
    grid-template-columns: auto 1fr auto auto;
    align-items: center;
    max-width: 1440px;
    height: 100%;
    margin: 0 auto;
    padding: 0 80px;
}

.logo {
    width: 200px;
    height: 84px;
}

.logo img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.nav ul {
    display: flex;
    justify-content: center;
    list-style: none;
    gap: 40px;
    margin: 0;
    padding: 0;
}

.nav a {
    font-family: 'Fraunces', serif;
    font-weight: 900;
    font-size: 18px;
    line-height: 140%;
    text-decoration-line: underline;
    color: #075985;
}

.nav a.active {
    color: #059669;
}

.desktop-auth-buttons,
.mobile-auth-buttons {
    display: flex;
    gap: 20px;
}

.desktop-auth-buttons a,
.mobile-auth-buttons a {
    font-family: 'Fraunces', serif;
    font-weight: 700;
    font-size: 16px;
    line-height: 120%;
    padding: 16px 40px;
    border-radius: 166px;
    cursor: pointer;
    text-decoration: none;
    display: inline-block;
    text-align: center;
}

a.login-btn {
    color: #059669;
    background: transparent;
    border: 1px solid #059669;
}

a.signup-btn {
    color: #FFFBEB;
    background: #059669;
    border: none;
}

.menu-toggle {
    display: none;
}

.mobile-auth-buttons {
    display: none;
}

@media (max-width: 1024px) {
    .container {
        grid-template-columns: auto 1fr auto;
        padding: 0 20px;
    }

    .nav {
        position: fixed;
        top: 120px;
        left: 0;
        width: 100%;
        height: calc(100vh - 120px);
        background: rgba(240, 235, 230, 0.95);
        transform: translateX(-100%);
        transition: transform 0.3s ease-in-out;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        padding-top: 20px;
    }

    .nav.open {
        transform: translateX(0);
    }

    .nav ul {
        flex-direction: column;
        align-items: center;
    }

    .mobile-auth-buttons {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 40px;
        gap: 20px;
    }

    .mobile-auth-buttons a {
        width: 200px;
    }

    .desktop-auth-buttons {
        display: none;
    }

    .menu-toggle {
        display: block;
        font-size: 24px;
        background: none;
        border: none;
        cursor: pointer;
        justify-self: end;
    }

    .header.menu-open {
        position: fixed;
        width: 100%;
    }
}

@media (max-width: 768px) {
    .header {
        height: 80px;
    }

    .logo {
        width: 150px;
        height: 63px;
    }

    .nav {
        top: 80px;
        height: calc(100vh - 80px);
    }
}