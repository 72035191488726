.features {
  display: grid;
  grid-template-rows: auto auto auto auto;
  gap: 80px;
  padding: 80px 20px;
  max-width: 1400px;
  margin: 0 auto;
}

.features-main {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 40px;
  align-items: center;
}

.features-image {
  width: 100%;
  height: auto;
  max-height: 506px;
  object-fit: cover;
}

.features-content {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.features-content h2 {
  font-family: 'Fraunces', serif;
  font-weight: 900;
  font-size: 48px;
  line-height: 120%;
  color: #334155;
}

.features-content p {
  font-family: 'Fraunces', serif;
  font-weight: 400;
  font-size: 18px;
  line-height: 160%;
  color: #44403C;
}

.features-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 32px;
  padding: 80px 0;
}

.feature-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 26px;
}

.feature-item h3 {
  font-family: 'Fraunces', serif;
  font-weight: 900;
  font-size: 32px;
  line-height: 140%;
  color: #334155;
}

.feature-item p {
  font-family: 'Fraunces', serif;
  font-weight: 400;
  font-size: 18px;
  line-height: 160%;
  color: #44403C;
}

.enroll-button {
  justify-self: center;
  font-family: 'Fraunces', serif;
  font-weight: 900;
  font-size: 24px;
  line-height: 120%;
  color: #FFFBEB;
  background-color: #059669;
  border: none;
  border-radius: 166px;
  padding: 16px 40px;
  cursor: pointer;
  grid-column: 1 / -1;
}

.features-paycheque {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 40px;
  align-items: center;
}

.paycheque-content {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.paycheque-content h2 {
  font-family: 'Fraunces', serif;
  font-weight: 900;
  font-size: 48px;
  line-height: 120%;
  color: #334155;
}

.paycheque-content p {
  font-family: 'Fraunces', serif;
  font-weight: 400;
  font-size: 18px;
  line-height: 160%;
  color: #44403C;
}

.paycheque-image {
  width: 100%;
  height: auto;
  max-height: 437px;
  object-fit: contain;
}

.features-money-smarts {
  display: grid;
  gap: 40px;
  justify-items: center;
  padding: 80px 0;
}

.features-money-smarts h2 {
  font-family: 'Fraunces', serif;
  font-weight: 900;
  font-size: 48px;
  line-height: 120%;
  text-align: center;
  color: #44403C;
  max-width: 829px;
}

.money-smarts-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 32px;
  max-width: 1303px;
  width: 100%;
}

.money-smart-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 26px;
}

.money-smart-image {
  width: 100%;
  height: auto;
  max-height: 323px;
  object-fit: cover;
  opacity: 0.7;
}

.silhouette-start {
  margin-bottom: 20px;
}

.money-smart-item h3 {
  font-family: 'Fraunces', serif;
  font-weight: 900;
  font-size: 32px;
  line-height: 140%;
  color: #334155;
}

.money-smart-item p {
  font-family: 'Fraunces', serif;
  font-weight: 400;
  font-size: 18px;
  line-height: 160%;
  color: #44403C;
}

.signup-button {
  font-family: 'Fraunces', serif;
  font-weight: 900;
  font-size: 24px;
  line-height: 120%;
  color: #FFFBEB;
  background-color: #059669;
  border: none;
  border-radius: 166px;
  padding: 16px 40px;
  cursor: pointer;
}

@media (max-width: 1024px) {
  .features {
    gap: 60px;
    padding: 60px 20px;
  }

  .features-main,
  .features-paycheque {
    grid-template-columns: 1fr;
  }

  .features-grid {
    grid-template-columns: repeat(2, 1fr);
  }

  .paycheque-image {
    order: -1;
  }

  .money-smarts-grid {
    grid-template-columns: repeat(2, 1fr);
  }

  .features-money-smarts h2 {
    font-size: 36px;
  }
}

@media (max-width: 768px) {
  .features {
    gap: 40px;
    padding: 40px 20px;
  }

  .features-grid {
    grid-template-columns: 1fr;
  }

  .features-content h2,
  .paycheque-content h2 {
    font-size: 36px;
  }

  .feature-item h3 {
    font-size: 28px;
  }

  .feature-item p,
  .features-content p,
  .paycheque-content p {
    font-size: 16px;
  }

  .enroll-button {
    font-size: 20px;
    padding: 12px 30px;
  }

  .money-smarts-grid {
    grid-template-columns: 1fr;
  }

  .features-money-smarts h2 {
    font-size: 28px;
  }

  .money-smart-item h3 {
    font-size: 24px;
  }

  .money-smart-item p {
    font-size: 16px;
  }

  .signup-button {
    font-size: 20px;
    padding: 12px 30px;
  }
}