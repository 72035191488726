.footer {
  position: relative;
  display: grid;
  grid-template-rows: auto 564px;
  width: 100%;
  overflow: hidden;
}

.footer-content {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-areas:
    "logo links"
    "logo copyright";
  gap: 40px;
  padding: 80px 20px;
  max-width: 1400px;
  margin: 0 auto;
  z-index: 1;
  width: 100%;
}

.footer-logo {
  grid-area: logo;
  width: 184px;
  height: auto;
}

.footer-links {
  grid-area: links;
  display: flex;
  justify-content: flex-end;
  gap: 40px;
  display: none;
}

.footer-links a {
  font-family: 'Fraunces', serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  text-decoration-line: underline;
  color: #059669;
}

.footer-copyright {
  grid-area: copyright;
  font-family: 'Fraunces', serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  color: #000000;
  text-align: right;
}

.footer-background {
  grid-row: 2;
  width: 100%;
  height: 564px;
  background: url('/public/assets/Golden-Bridge-over-Water.png') no-repeat center bottom;
  background-size: contain;
  mix-blend-mode: darken;
}

@media (max-width: 1024px) {
  .footer-content {
    grid-template-columns: 1fr;
    grid-template-areas:
    "links"
      "logo"
      "copyright";
    justify-items: center;
    text-align: center;
  }

  .footer-links {
    justify-content: center;
  }

  .footer-copyright {
    text-align: center;
  }
}

@media (max-width: 768px) {
  .footer-links {
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }

  .footer {
    grid-template-rows: auto 150px;
  }

  .footer-background {
    height: 150px;

  }
}