.main-cta {
  display: grid;
  grid-template-columns: 1fr auto;
  gap: 40px;
  align-items: center;
  padding: 80px 20px;
  max-width: 1400px;
  margin: 0 auto;
}

.cta-content {
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.cta-content h2 {
  font-family: 'Fraunces', serif;
  font-style: normal;
  font-weight: 900;
  font-size: 48px;
  line-height: 120%;
  color: #334155;
}

.cta-content p {
  font-family: 'Fraunces', serif;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 140%;
  color: #44403C;
}

.cta-buttons {
  display: grid;
  grid-template-columns: auto auto;
  gap: 18px;
}

.cta-button {
  font-family: 'Fraunces', serif;
  font-style: normal;
  font-weight: 900;
  font-size: 24px;
  line-height: 120%;
  padding: 16px 40px;
  border-radius: 166px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
}

.cta-button.signup {
  background-color: #059669;
  color: #FFFBEB;
  border: none;
}

.cta-button.signup:hover {
  background-color: #047857;
}

.cta-button.talk-to-sales {
  background-color: transparent;
  color: #059669;
  border: 1px solid #059669;
}

.cta-button.talk-to-sales:hover {
  background-color: #059669;
  color: #FFFBEB;
}

@media (max-width: 1024px) {
  .main-cta {
    grid-template-columns: 1fr;
    text-align: center;
  }

  .cta-buttons {
    justify-content: center;
  }
}

@media (max-width: 768px) {
  .cta-content h2 {
    font-size: 36px;
  }

  .cta-content p {
    font-size: 16px;
  }

  .cta-buttons {
    grid-template-columns: 1fr;
  }

  .cta-button {
    font-size: 20px;
    padding: 12px 30px;
  }
}